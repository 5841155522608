import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Volunteerism = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="volunteerism-title">
          Volunteerism
        </h1>
        <p>
          WaFd employees are invested in their communities and giving back. We support this by giving them time to
          volunteer with the organizations they are passionate about. In 2023, WaFd employees participated in 11,870
          volunteer hours to more than 755 organizations and initiatives across our regional footprint.
        </p>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/id-food-bank.png"
              alt="WaFd employees volunteering, Food Bank in Idaho"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Food Bank (Idaho)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/wa-stuff-the-bus.png"
              alt="WaFd employees volunteering Stuff the Bus, Helping Hands in Washington"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Stuff the Bus Helping Hands (Washington)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/ut-project-homeless.png"
              alt="WaFd employees volunteering for Project Homeless Connect in Utah"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Project Homeless Connect (Utah)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/az-save-the-family-food-drive.png"
              alt="WaFd employees volunteering for Save the Family Food Drive in Mesa, Arizona"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Save the Family Food Drive (Arizona)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/or-united-day-caring.png"
              alt="WaFd employees volunteering for United Way Day of Caring in Oregon"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">United Way Day of Caring (Oregon)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/nm-girls-into-tech.png"
              alt="WaFd employees volunteering Albuquerque, New Mexico"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Girls Into Tech (New Mexico)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/nv-teaching-financial-literacy.png"
              alt="WaFd employees volunteering for Financial Literacy in Nevada"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">Teaching Financial Literacy (Nevada)</h4>
          </div>
          <div className="col-md-6 mb-3">
            <StaticImage
              src="../../images/tx-food-bank.png"
              alt="WaFd employees presenting a check for Builders of Hope for $25,000 in Texas"
              placeholder="blurred"
              quality="100"
              className="mb-2"
            />

            <h4 className="text-green-60 font-weight-semi-bold">We're In This Together (Texas)</h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default Volunteerism;
