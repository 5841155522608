import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const DiversityEquityAndInclusion = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="diversity-equity-inclusion-title">
          Diversity, Equity, and Inclusion
        </h1>
        <h4>
          We are committed to supporting a diverse employee base, creating a culture of inclusion, equity, and
          empowerment, and bringing our full selves to work. Diversity can take many forms, including race, nationality,
          ethnicity, sexual orientation, religion, disability, gender, age, protected veteran status, or other
          distinguishing characteristics.
        </h4>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-lg-6 mb-3 mb-lg-0">
            <StaticImage
              src="../../images/about-us/investor-relations/csr/thumbnail-dei-food-lifeline-110524.jpg"
              alt="A diverse group of WaFd employees at the Food Lifeline building."
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />
            <p>
              Our mission is clear: "We are committed to building diverse teams and creating a culture of inclusion,
              empowerment, and belonging."
            </p>
            <p>
              To advance this mission, we’ve restructured our DEI program. Each region will have 5-6 members, with the
              Regional President as the executive sponsor. A dedicated council for our corporate support areas,
              sponsored by the Chief Financial Officer and Chief Commercial Banker, with direct involvement from our D&I
              Officer. Our D&I Officer works closely with the Chief Financial Officer, Chief Commercial Banker, and
              Chief People Officer to align efforts across all areas of the Bank.
            </p>
            <p>This structure ensures DEI initiatives are integrated across the nine states we serve.</p>
            <p className="mb-2">
              <strong>Key Focus Areas</strong>
            </p>
            <ul id="key-focus-area-list">
              <li id="key-focus-area-list-item-1">
                Partnered with a DEI Consultant to build inclusive mindsets and sustainable strategies
              </li>
              <li id="key-focus-area-list-item-2">
                Enhanced recruitment, hiring, and retention to foster a more diverse workforce
              </li>
              <li id="key-focus-area-list-item-3">
                Launched Employee Resource Groups (ERGs) to promote belonging. Initial ERGs include:
                <ul id="key-focus-area-ergs-list" className="list-style-type-disc">
                  <li id="key-focus-area-ergs-list-item-1" className="mb-0 mt-1">
                    <strong>BIPOC</strong> (Black, Indigenous, People of Color)
                  </li>
                  <li id="key-focus-area-ergs-list-item-2" className="mb-0">
                    <strong>Emerging Leaders</strong>
                  </li>
                  <li id="key-focus-area-ergs-list-item-3" className="mb-0">
                    <strong>Working Parents</strong>
                  </li>
                  <li id="key-focus-area-ergs-list-item-4" className="mb-0">
                    <strong>LGBTQ+</strong>
                  </li>
                  <li id="key-focus-area-ergs-list-item-5" className="mb-0">
                    <strong>Military Service Members</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <StaticImage
              src="../../images/about-us/investor-relations/csr/thumbnail-dei-pride-110524.jpg"
              alt="A diverse group of WaFd employees at a Seattle pride event."
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />
            <p>
              <strong>Global Diversity and Inclusion Advisory Council</strong>
            </p>
            <p className="mb-2">
              Senior leaders, alongside the D&I Officer, guide enterprise-wide DEI efforts through the Global Diversity
              and Inclusion Advisory Council, ensuring alignment between regional and corporate initiatives. The
              Advisory Council:
            </p>
            <ul id="advisory-council-list" className="my-2">
              <li id="advisory-council-list-item-1">
                <strong>Provides strategic guidance</strong> to regional and corporate councils
              </li>
              <li id="advisory-council-list-item-2">
                <strong>Facilitates enterprise-wide DEI strategies</strong>
              </li>
              <li id="advisory-council-list-item-3">
                <strong>Supports local and corporate DEI efforts</strong>
              </li>
              <li id="advisory-council-list-item-4">
                <strong>Defines council membership requirements</strong>, including appointed and volunteer roles
              </li>
            </ul>
            <p>
              With this structure, WaFd Bank reaffirms its belief that{" "}
              <em>
                "diversity, equity, and inclusion drive innovation and productivity, allowing us to better serve our
                communities and each other."
              </em>
            </p>
            <p className="mb-2">
              <strong>2,120 Team Members</strong>
            </p>
            <ul id="team-members-breakdown-list" className="mb-0">
              <li id="team-members-breakdown-list-item-1">33% Non-White</li>
              <li id="team-members-breakdown-list-item-2">2.22% US Veteran</li>
              <li id="team-members-breakdown-list-item-3">5.93% Differently Abled</li>
              <li id="team-members-breakdown-list-item-4">67% Female</li>
              <li id="team-members-breakdown-list-item-5" className="mb-0">
                33% Male
              </li>
            </ul>
            <div className="border-radius-12 bg-blue-10 mt-4 p-3" id="diversity-and-inclusion-officer-box">
              <div className="d-flex flex-row">
                <div>
                  <h5>Meet our Diversity & Inclusion Officer!</h5>
                  <p>
                    "Welcome! Your unique perspective strengthens our culture of embracing diversity & inclusion, where
                    you're valued, respected, and empowered to grow."
                  </p>
                  <p className="mb-0">
                    <strong>Larry Nelson</strong>
                  </p>
                  <p className="mb-0">Diversity & Inclusion Officer</p>
                </div>
                <div>
                  <StaticImage
                    src="../../images/about-us/investor-relations/csr/headshot-larry-nelson-90.jpg"
                    alt="Headshot of Larry Nelson, our Diversity & Inclusion Officer."
                    placeholder="blurred"
                    quality="100"
                    style={{ width: "90px", height: "90px" }}
                    className="mb-3 rounded-circle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiversityEquityAndInclusion;
