import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";

import Icon from "../custom-widgets/icon";

const Governance = () => {
  const imgData = useStaticQuery(graphql`
    query {
      stephenGraham: file(relativePath: { eq: "employees/headshot-stephen-graham-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      davidGrant: file(relativePath: { eq: "employees/headshot-david-grant-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      randyTalbot: file(relativePath: { eq: "employees/headshot-randy-talbot-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      steveSingh: file(relativePath: { eq: "employees/headshot-steve-singh-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      lindaBrower: file(relativePath: { eq: "employees/headshot-linda-bower-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      sylviaHampel: file(relativePath: { eq: "employees/headshot-sylvia-hampel-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      seanSingleton: file(relativePath: { eq: "employees/headshot-sean-singleton-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      shawnBice: file(relativePath: { eq: "employees/headshot-shawn-bice-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      brentBeardall: file(relativePath: { eq: "employees/headshot-brent-beardall-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      maxYzaguirre: file(relativePath: { eq: "employees/headshot-m-max-yzaguirre-020524.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      bradleyShuster: file(relativePath: { eq: "employees/headshot-bradley-m-shuster-020524.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  const boardOfDirectorsData = [
    {
      name: "Stephen M. Graham",
      role: "Board of Director, Chairman of the Board",
      imageUrl: imgData.stephenGraham.childImageSharp.gatsbyImageData
    },
    {
      name: "Brent Beardall",
      role: "President, Chief Executive Officer, Vice Chairman",
      imageUrl: imgData.brentBeardall.childImageSharp.gatsbyImageData
    },
    {
      name: "David Grant",
      imageUrl: imgData.davidGrant.childImageSharp.gatsbyImageData
    },
    {
      name: "Randy H. Talbot",
      imageUrl: imgData.randyTalbot.childImageSharp.gatsbyImageData
    },
    {
      name: "Steve Singh",
      imageUrl: imgData.steveSingh.childImageSharp.gatsbyImageData
    },
    {
      name: "Linda Brower",
      imageUrl: imgData.lindaBrower.childImageSharp.gatsbyImageData
    },
    {
      name: "Sylvia Hampel",
      imageUrl: imgData.sylviaHampel.childImageSharp.gatsbyImageData
    },
    {
      name: "Sean Singleton",
      imageUrl: imgData.seanSingleton.childImageSharp.gatsbyImageData
    },
    {
      name: "Shawn Bice",
      imageUrl: imgData.shawnBice.childImageSharp.gatsbyImageData
    },
    {
      name: "M. Max Yzaguirre",
      imageUrl: imgData.maxYzaguirre.childImageSharp.gatsbyImageData
    },
    {
      name: "Bradley M. Shuster",
      imageUrl: imgData.bradleyShuster.childImageSharp.gatsbyImageData
    }
  ];

  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="governance-title">
          Governance
        </h1>
        <p>
          We are committed to strong corporate governance principles and practices which begins with our Board of
          Directors. Our Board endorses the view that one of its primary functions is to protect stakeholder interests
          by providing oversight of management, including the Chief Executive Officer.
        </p>
      </section>
      <section className="container pb-0">
        <div className="row">
          {boardOfDirectorsData.map((data, index) => (
            <div
              key={index}
              id={`bod-${data.name.toLocaleLowerCase().replace(/\s+/g, "-")}-info`}
              className="col-sm-4 col-md-3 col-lg-2 mb-4 text-center"
            >
              <GatsbyImage image={data.imageUrl} alt={`${data.name}, WaFd Bank ${data.role}`} />
              <div className="font-weight-semi-bold text-gray-80 mt-3">{data.name}</div>
              <div>{data.role}</div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              The Board is made up of members with a wealth of experience and knowledge from a variety of industries
              including finance, technology, real estate, investments, and risk management. Our Board is committed to
              independence and the majority of Board members are independent pursuant to the listing requirements of the
              Nasdaq Stock Market.{" "}
              <Link
                id="governance-leadership-team-link"
                to="/about-us/investor-relations/leadership-board"
                className="font-weight-bold text-decoration-none"
              >
                WaFd Bank Leadership Team <Icon name="arrow-right" class="ml-1" />
              </Link>
            </p>
            <p>
              We maintain a variety of{" "}
              <Link id="governance-corporate-governance-link" to="/about-us/investor-relations/corporate-governance">
                corporate governance documents and policies
              </Link>{" "}
              which can be found on our website.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="table-responsive">
              <table id="diversity-table" className="table table-bordered">
                <thead className="bg-info text-white">
                  <tr>
                    <th colSpan="3" id="bdm-title">
                      Board Diversity Matrix
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td id="bdm-total-directors" colSpan={3}>
                      Total Number of Directors: 11
                    </td>
                  </tr>
                  <tr className="bg-secondary text-white font-weight-bold">
                    <td id="bdm-gender-identity">Part I: Gender Identity</td>
                    <td style={{ width: "20%" }} id="bdm-gender-female">
                      Female
                    </td>
                    <td style={{ width: "20%" }} id="bdm-gender-male">
                      Male
                    </td>
                  </tr>
                  <tr>
                    <td id="board-diversity-gender-identity-totals">Directors</td>
                    <td className="align-middle text-center text-sm-left" id="bdm-gender-identity-female">
                      2
                    </td>
                    <td className="align-middle text-center text-sm-left" id="bdm-gender-identity-male">
                      9
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      className="font-weight-bold bg-secondary text-white"
                      id="bdm-demographic-background"
                    >
                      Part II: Demographic Background
                    </td>
                  </tr>
                  <tr>
                    <td id="bdm-demgraphic-background-aa-black">African American or Black</td>
                    <td
                      className="align-middle text-center text-sm-left"
                      id="bdm-demgraphic-background-aa-black-females"
                    >
                      0
                    </td>
                    <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-aa-black-males">
                      2
                    </td>
                  </tr>
                  <tr>
                    <td id="bdm-demgraphic-background-hispanic-latinx">Hispanic or Latinx</td>
                    <td
                      className="align-middle text-center text-sm-left"
                      id="bdm-demgraphic-background-hispanic-latinx-females"
                    >
                      1
                    </td>
                    <td
                      className="align-middle text-center text-sm-left"
                      id="bdm-demgraphic-background-hispanic-latinx-males"
                    >
                      1
                    </td>
                  </tr>
                  <tr>
                    <td id="bdm-demgraphic-background-asian">Asian</td>
                    <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-asian-females">
                      0
                    </td>
                    <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-asian-males">
                      1
                    </td>
                  </tr>
                  <tr>
                    <td id="bdm-demgraphic-background-white">White</td>
                    <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-white-females">
                      1
                    </td>
                    <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-white-males">
                      5
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Governance;
