import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FromOurPresidentAndCeo = () => {
  return (
    <section className="container pb-0">
      <h1 className="text-success" id="from-our-president-title">
        From our President and CEO
      </h1>
      <div className="row">
        <div className="col-md-6">
          <p>
            Founded on April 24, 1917 in Ballard, Washington, Washington Federal Bank, a federally insured Washington
            state chartered commercial bank dba WaFd Bank (the "Bank" or "WaFd Bank"), is engaged primarily in providing
            lending, depository, insurance and other banking services to consumers, mid-sized to large businesses, and
            owners and developers of commercial real estate. Ever since our start in 1917, we've been helping to build
            healthy, thriving communities. We're linked together as a team across nine western states serving clients
            and communities to fuel economic growth and support personal and business financial health. Equally
            important as our banking reputation is our commitment to community and social responsibility. We're issuing
            this report to outline the activities and initiatives WaFd bankers are focused on, the investments we're
            making, and our goals for making a difference in the communities we serve.
          </p>
          <p>
            Banking today looks different than when we started 106 years ago. Yet, one thing has remained the same — we
            are here to make the best choices on any given day, for our clients, our employees, our communities, and our
            shareholders, in good times and bad. We want our families and friends to be proud that we work at WaFd Bank.
            More than anything, I'm personally humbled to work alongside colleagues who clearly love what they do and
            are here to make a difference in their communities. Every year for as long as any of us can remember, the
            Bank has supported United Way during their corporate giving campaign. We match, dollar-for-dollar, the
            donations our employees make to the United Way to provide a community safety net. We also ask that our
            bankers volunteer in their communities, whether it's acting as an instructor for Junior Achievement at the
            local high school or packaging nutritious meals at their community food bank.
          </p>
        </div>
        <div className="col-md-6">
          <p>
            During the past fiscal year, our 11 regional Diversity and Inclusion councils, made up of volunteers, worked
            to enhance employment and recruitment diversity, participated in local activities supporting our community,
            and formed five different affinity groups to support inclusion and celebrate one another in the workplace.
          </p>
          <p>
            It's imperative that our teams cultivate enduring relationships with clients, communities, and colleagues.
            As we continue to grow, we endeavor to stand apart from our competitors, and be thoughtful about shaping the
            neighborhoods around us. We believe banking should be an empowering experience, not an intimidating one.
            Therefore, we focus on personal progress rather than restrictions, and always strive to see things from our
            clients' points of view. That's how we live our Bank motto: "Love what you do and make a difference."
          </p>

          <p>Our hope is you will find this report to be engaging, helpful, and informative.</p>

          <div className="row">
            <div className="col-4">
              <StaticImage
                src="../../images/employees/headshot-brent-beardall-021523.jpg"
                alt="Brent Beardall, President and CEO, WaFd Bank"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-8">
              <p>Respectfully,</p>
              <p>
                <strong>Brent Beardall</strong> <br />
                President &amp; Chief Executive Officer
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FromOurPresidentAndCeo;
