import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import "./csr.bootstrap.scss";

import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import SEO from "../../../components/seo/seo";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import FromOurPresident from "../../../components/csr/from-our-president";
import CommunityDevelopment from "../../../components/csr/community-development";
import WaFdFoundationAndCommunityGiving from "../../../components/csr/wafd-foundation-and-community-giving";
import Volunteerism from "../../../components/csr/volunteerism";
import FinancialLiteracy from "../../../components/csr/financial-literacy";
import DiversityEquityAndInclusion from "../../../components/csr/diversity-equity-and-inclusion";
import CommunityPartnerships from "../../../components/csr/community-partnerships";
import WaFdWorkforce from "../../../components/csr/wafd-workforce";
import Governance from "../../../components/csr/governance";
import EnvironmentalResponsibilityAndSocialManagement from "../../../components/csr/environmental-responsibility-and-social-risk-management";

const CorporateAndSocialResponsibility = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-and-social-responsibility/hero-csr-112224-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const title = "Corporate Social Responsibility (CSR)";
  const description =
    "Learn about WaFd Bank's Corporate Social Responsibility (CSR) and how banking is an empowering experience to build healthy, thriving, communities.";

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Corporate and Social Responsibility"
    }
  ];

  const SEOData = {
    title: "Corporate Social Responsibility (CSR)",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/csr"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-csr-112224.jpg"
      }
    ]
  };

  const heroData = {
    id: "corporate-social-responsibility-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd Bank CEO and employees presenting a 1 million dollar check to United Way.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "At WaFd Bank, we love what we do and making a difference in the communities we serve."
          }
        }
      ]
    }
  };

  const tabsData = [
    {
      title: "From our President and CEO",
      component: <FromOurPresident />,
      id: "from-our-president"
    },
    {
      title: "Financial Literacy",
      component: <FinancialLiteracy />,
      id: "financial-literacy"
    },

    {
      title: "Community Development",
      component: <CommunityDevelopment />,
      id: "community-development"
    },
    {
      title: "Diversity, Equity, and Inclusion",
      component: <DiversityEquityAndInclusion />,
      id: "diversity-equity-and-inclusion"
    },
    {
      title: "Governance",
      component: <Governance />,
      id: "governance"
    },
    {
      title: "Washington Federal Foundation and Community Giving",
      component: <WaFdFoundationAndCommunityGiving />,
      id: "wafd-foundation-and-community-giving"
    },
    {
      title: "Community Partnerships",
      component: <CommunityPartnerships />,
      id: "community-partnerships"
    },
    {
      title: "WaFd Workforce",
      component: <WaFdWorkforce />,
      id: "wafd-workforce"
    },
    {
      title: "Environmental Responsibility and Social Risk Management",
      component: <EnvironmentalResponsibilityAndSocialManagement />,
      id: "environmental-responsibility-social-management"
    },
    {
      title: "Volunteerism",
      component: <Volunteerism />,
      id: "volunteerism"
    },
    {
      title: "2023 ESG Report",
      url: "/documents/wafdbank-esg-report.pdf",
      id: "esg-report-link",
      document: true
    }
  ];

  // Handling of active tab.
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  useEffect(() => {
    if (location.hash) {
      const index = tabsData.findIndex((tab) => tab.id === location.hash.replace("#", ""));
      setActiveTabIndex(index || 0);
    } else {
      setActiveTabIndex(0);
    }
  }, [location.hash]);

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="mt-4 bg-light" id="csr-nav">
        <div className="container">
          <div className="row">
            {tabsData.map((tab, index) => (
              <div className={`my-2 col-sm-6 col-md-4`} key={index}>
                {tab.document ? (
                  <a
                    className="csr-nav-link text-gray-70 text-decoration-none"
                    id={tab.id}
                    rel="noreferrer"
                    target="_blank"
                    href={tab.url}
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    {tab.title}
                  </a>
                ) : (
                  tab.title && (
                    <a
                      id={`${tab.id}-link`}
                      href={`#${tab.id}`}
                      className={`csr-nav-link text-gray-70 d-flex text-decoration-none ${
                        activeTabIndex === index ? "csr-nav-active-tab" : ""
                      }`}
                    >
                      <Icon name="arrow-right" class="mr-2 mt-1" />
                      {tab.title}
                    </a>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container">
        {tabsData.map((tab, index) => (
          <div key={index} className={activeTabIndex === index ? "d-block" : "d-none"} id={tab.id}>
            {tab.component}
          </div>
        ))}
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CorporateAndSocialResponsibility;
